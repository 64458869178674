.sectionMain {
  background: url("/images/bg-main-section.png");
  background-repeat: no-repeat;
  background-position: bottom left;
}

.sectionMainTitle {
  color: var(--orange);
}

.sectionMainContent {
  background: url("/images/bg-main-section-secondary.png");
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: 95% bottom;
  padding-right: 300px;
  padding-left: 130px;
  text-align: center;
}

.sectionMainContent h1 {
  font-size: 2.4em;
  line-height: 1.4em;
  text-align: left;
  margin: 96px 0 24px;
}

.sectionMainContent p {
  text-align: left;
}

.sectionMainContent img {
  width: 250px;
  height: auto;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .sectionMainContent {
    background-position: 60vw 30vh;
    padding-right: 0;
    padding-left: 0;
  }

  .sectionMainContent h1 {
    text-align: center;
    line-height: 1.5;
    font-size: 1.5rem;
    margin-top: -2rem;
  }

  .sectionMainContent p {
    text-align: center;
    color: #eee;
    font-size: 1.2rem;
    margin: 16px;
  }

  .sectionMainContent img {
    display: none;
  }
}
