.registerForm {
  position: relative;
  background: #fff;
  width: auto;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 90vh;
  border-radius: 20px;
  overflow: hidden;
}

.registerFormImage {
  max-width: 150px;
  width: 40%;
  background-color: #0098ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registerFormImage img {
  width: 100px;
  height: auto;
}

.registerFormForm {
  max-width: 450px;
  width: 100%;
  background-color: #fff;
  overflow-y: scroll;
  padding: 8px;
  text-align: right;
}

.registerFormForm h3 {
  color: #062342;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  line-height: 1.1;
  margin: 8px 0;
}

.registerFormForm p {
  font-size: 1rem;
  color: #214a81;
  text-align: left;
  margin: 4px 0;
}

.registerFormForm input[type="text"],
.registerFormForm input[type="password"] {
  border: 1px solid #f1f1f1;
  line-height: 24px;
  font-size: 16px;
  background: #f1f1f1;
  width: 100%;
  margin-bottom: 8px;
}

.registerFormBtn {
  background: #0098ec;
  color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #0098ec;
  line-height: 1.5rem;
  padding: 0 8px;
  margin: 24px 4px 0;
}

@media only screen and (max-width: 767px) {
  .registerForm {
    flex-direction: column;
    height: 80vh;
  }

  .registerFormImage {
    max-width: 100%;
    width: 100%;
    height: 100px;
  }

  .registerFormImage img {
    width: auto;
    height: 80px;
  }

  .registerFormForm {
    width: 100%;
    padding: 8px;
  }

  .registerFormForm h3 {
    font-size: 1.5rem;
  }

  .registerFormForm p {
    font-size: 1rem;
  }

  .registerFormForm input[type="text"],
  .registerFormForm input[type="password"] {
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .registerFormBtn {
    line-height: 1.5rem;
    padding: 0 8px;
  }
}

.registerFormTerms {
  font-size: 0.8rem !important;
}
