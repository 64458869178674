.sectionAboutUs {
  background: #fff;
  color: var(--main-color);
  height: auto !important;
}

.sectionAboutUsContent {
  padding: 32px 0;
  height: auto !important;
  text-align: center;
}

.sectionAboutUsContent h2 {
  font-size: 2.5rem;
  line-height: 1.3;
  text-align: left;
  padding: 0 16px;
  width: 270px;
  font-weight: bold;
}

.sectionAboutUs ul {
  display: flex;
  margin: 16px auto;
  list-style: none;
  flex-direction: row;
  justify-content: center;
  color: var(--main-color);
}

.sectionAboutUs ul li {
  max-width: 250px;
}

.sectionAboutUs ul li p {
  color: var(--main-color);
  font-size: 1rem;
}

.sectionAboutUsContent h3 {
  font-size: 1.5rem;
  line-height: 1.3;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .sectionAboutUs ul {
    flex-wrap: wrap;
  }

  .sectionAboutUs ul li:nth-child(1),
  .sectionAboutUs ul li:nth-child(3) {
    margin-top: 1rem;
    width: 50%;
  }

  .sectionAboutUs ul li:nth-child(5) {
    margin-top: 1rem;
    width: 100%;
  }

  .sectionAboutUs ul li:nth-child(2),
  .sectionAboutUs ul li:nth-child(4) {
    display: none;
  }

  .sectionAboutUs ul li p {
    color: var(--main-color);
    font-size: 1rem;
  }

  .sectionAboutUsContent h3 {
    font-size: 1.5rem;
    line-height: 1.3;
    text-align: center;
  }
}
