.registerQuestions {
  position: relative;
  background: #fff;
  width: auto;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  height: 70vh;
  border-radius: 20px;
  overflow: hidden;
}

.registerQuestionsImage {
  max-width: 150px;
  width: 40%;
  background-color: #0098ec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registerQuestionsImage img {
  width: 230px;
  height: auto;
}

.registerQuestionsForm {
  max-width: 450px;
  background-color: #fff;
  padding: 16px;
  text-align: right;
}

.registerQuestionsForm h3 {
  color: #062342;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  margin: 4px 0;
  text-align: left;
}

.registerQuestionsForm p {
  font-size: 1rem;
  color: #214a81;
  margin: 8px 0;
  text-align: left;
}

.registerQuestionsForm label {
  display: inline-block;
  font-size: 0.8rem;
  color: #999999;
  margin: 8px 0;
  width: 100%;
  text-align: left;
}

.registerQuestionsForm input[type="text"] {
  border: 1px solid #f1f1f1;
  line-height: 24px;
  font-size: 1rem;
  background: #f1f1f1;
  width: 100%;
  margin-bottom: 8px;
}

.registerQuestionsBtn {
  background: #0098ec;
  color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #0098ec;
  line-height: 1.5rem;
  padding: 0 8px;
  margin-top: 24px;
}

@media only screen and (max-width: 767px) {
  .registerQuestions {
    flex-direction: column;
    height: 65vh;
  }

  .registerQuestionsImage {
    max-width: 100%;
    width: 100%;
    height: 100px;
  }

  .registerQuestionsImage img {
    width: auto;
    height: 80px;
  }

  .registerQuestionsForm {
    width: 100%;
    padding: 8px;
  }

  .registerQuestionsForm h3 {
    font-size: 1.5rem;
  }

  .registerQuestionsForm p,
  .registerQuestionsForm label {
    font-size: 1rem;
  }

  .registerQuestionsForm input[type="text"],
  .registerQuestionsForm input[type="password"] {
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .registerQuestionsBtn {
    line-height: 1.5rem;
    padding: 0 8px;
  }
}
