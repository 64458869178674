.sectionProductsContent {
  background: url("/images/bg-pwa-section-secondary.png");
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: -150px bottom;
  justify-content: flex-start;
  padding: 128px 0 0 300px;
}

.sectionProductsContent h2 {
  font-size: 4rem;
  text-transform: uppercase;
}

.sectionProductsContentList {
  display: flex;
  flex-direction: row;
  margin: 64px auto 0;
}

.sectionProductsContentList > img {
  width: 100%;
  max-width: 300px;
  height: auto;
  align-self: flex-start;
}

.sectionProductsContentList > ul {
  flex: 1;
  list-style: none;
}

.sectionProductsContentList > ul > li {
  display: inline-block;
  margin: 32px 0 0;
  padding-left: 24px;
}

.sectionProductsContentList > ul > li:last-child {
  text-align: center;
  margin: 0;
  width: 100%;
}

.sectionProductsContentList > ul img {
  width: 32px;
  height: auto;
  vertical-align: middle;
  float: left;
  margin-right: 8px;
}

@media only screen and (max-width: 767px) {
  .sectionProductsContent {
    background: url("/images/bg-pwa-section-secondary.png");
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: -200px center;
    justify-content: flex-start;
    padding: 4rem 16px;
  }

  .sectionProductsContentList {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
  }

  .sectionProductsContentList > img {
    display: none;
  }

  .sectionProductsContent h2 {
    font-size: 3rem;
    text-align: center;
    width: 100%;
  }

  .sectionProducts li {
    margin: 16px 0;
    line-height: 1.1em;
    text-align: left;
  }

  .sectionProductsContentList > ul {
    width: 80%;
    margin-left: 20%;
  }
}
