.privacy {
  max-width: 80%;
  margin: 64px auto;
}

.privacy h1 {
  font-size: 1.5rem;
  text-align: center;
  margin: 16px auto;
}

.privacy h2 {
  font-size: 1.4rem;
  margin: 8px auto;
}

.privacy h3 {
  font-size: 1.3rem;
  margin: 8px auto;
}

.privacy p {
  font-size: 0.8rem;
  margin: 4px auto;
  font-weight: normal;
}

.privacy ul {
  margin: 0 32px;
  font-size: 0.8rem;
}
