.sectionPricingPlans {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
}

.sectionPricingPlans > li {
  flex: 1;
  max-width: 250px;
  width: 90%;
  height: 580px;
  margin: 0 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 1px;
}

.sectionPricingExplanation {
  font-size: 0.7rem;
  text-align: center;
  margin-top: 1rem;
}

@media only screen and (max-width: 767px) {
  .sectionPricingPlans {
    padding-top: 32px;
    justify-content: center;
    flex-direction: column;
  }

  .sectionPricingPlans > li {
    max-width: 250px;
    min-height: 70vh;
    height: auto;
    margin: 16px auto;
  }
}

.sectionPricingName {
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 1rem;
  padding-top: 0.2rem;
  padding-left: 2.4rem;
  background-image: url("/images/icons/basic.svg");
  background-position: 4px 4px;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}

.sectionPricingPrice {
  background: #fff;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-left: -16px;
  padding-left: 16px;
  font-size: 32px;
  font-weight: bold;
  border-bottom: 6px solid #606060;
}

.sectionPricingPricePeriod {
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
}

.sectionPricingBasic {
  background: rgb(120, 255, 214);
  background: linear-gradient(
    195deg,
    rgba(120, 255, 214, 1) 0%,
    rgba(70, 180, 166, 1) 51%,
    rgba(2, 0, 36, 0.39119397759103647) 100%
  );
}

.sectionPricingPriceBasic {
  color: #46b4a6;
}

.sectionPricingStandard {
  background: rgb(214, 116, 148);
  background: linear-gradient(
    195deg,
    rgba(214, 116, 148, 1) 0%,
    rgba(137, 101, 157, 1) 51%,
    rgba(55, 86, 166, 1) 100%
  );
}

.sectionPricingNameStandard {
  background-image: url("/images/icons/standard.svg");
}

.sectionPricingPriceStandard {
  color: #89659d;
}

.sectionPricingPremium {
  background: rgb(255, 107, 148);
  background: linear-gradient(
    195deg,
    rgba(255, 107, 148, 1) 0%,
    rgba(255, 144, 138, 1) 51%,
    rgba(255, 107, 148, 1) 100%
  );
}

.sectionPricingNamePremium {
  background-image: url("/images/icons/premium.svg");
}

.sectionPricingPricePremium {
  color: #ff908a;
  font-size: 1.8rem;
  line-height: 2rem;
  text-transform: uppercase;
}

.sectionPricingDetails {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
}

.sectionPricingDetails li {
  line-height: 14px;
  font-size: 12px;
  width: 100%;
  margin: 8px 0;
  padding: 0 0 0 24px;
  background-image: url("/images/icons/tick.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px 16px;
}

.sectionPricingStartButton {
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0 0.5rem;
  line-height: 2rem;
  margin: auto auto 1rem;
  align-self: center;
  justify-self: flex-end;
  width: 150px;
}

.sectionPricingDetailsDisabled {
  opacity: 0.1;
}

@media only screen and (max-width: 767px) {
  .sectionPricingName {
    font-size: 2rem;
    padding-left: 3.2rem;
  }

  .sectionPricingDetails li {
    margin-left: 8px;
  }
}
