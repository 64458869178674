:root {
  --main-color: #0d0766;
  --main-font-color: #fff;
  --secondary-bg-color: #f51e07;
  --success-font-color: #1e8507;
  --orange: #ff9200;
}

/** RESET **/
* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  touch-action: manipulation;
}

body {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  margin: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  overflow-x: hidden;
  color: var(--main-font-color);
  background-color: var(--main-color) !important;
}

@media only screen and (max-width: 767px) {
  html,
  body {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
span,
li,
img,
inpot,
button {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  font-weight: inherit;
}

h1,
h2,
h3 {
  font-family: "Poppins", sans-serif;
}

p {
  line-height: 1.6;
  font-size: 1em;
  font-weight: 400;
  color: #f8f1f1;
}

h1 {
  font-size: 3.5em;
  line-height: 1;
}
h2 {
  font-size: 3em;
  line-height: 1.1;
}
h3 {
  font-size: 2.5em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 0.9em;
  letter-spacing: 1px;
}

a,
button {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
  line-height: 1;
}

a:focus,
a:active,
a:hover,
button:focus,
button:active,
button:hover,
a b.light-color:hover {
  text-decoration: none;
  opacity: 0.8;
}

b {
  font-weight: 500;
}

span {
  display: inline-block;
}

button {
  outline: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

#cookieConsent {
  background-color: white;
  color: var(--main-color);
  text-align: center;
  display: none;
  position: fixed;
  z-index: 65000;
  bottom: 0;
  width: 100%;
  font-size: 16px;
  line-height: 32px;
}

#cookieConsent button.cookieAccept {
  background: var(--main-color) !important;
  color: white;
  border: none;
  border-radius: 0.2em;
  margin: 0.5em;
  padding: 0 16px;
  line-height: 24px;
}

.btn-primary-action,
.btn-secondary-action {
  padding: 0 32px;
  background: var(--secondary-bg-color);
  color: var(--main-font-color);
  line-height: 32px;
  font-size: 18px;
  border-radius: 16px;
  max-width: 350px;
  margin: 32px auto;
}

.btn-secondary-action {
  background: #0098ec;
}

.main-scroller {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.main-scroller .section {
  display: block;
  height: 100vh;
  width: 100%;
}

.main-scroller .section .content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .main-scroller,
  .main-scroller .section,
  .main-scroller .section .content {
    min-height: 100vh;
    height: auto;
  }
}

.errorMsg,
.successMsg {
  border: var(--secondary-bg-color) 1px solid;
  color: var(--secondary-bg-color);
  text-align: center;
  width: 100%;
  font-size: 12px;
  margin: 8px 0;
  line-height: 16px;
  border-radius: 5px;
}

.successMsg {
  border: var(--success-font-color) 1px solid;
  color: var(--success-font-color);
}

.errorMsg.hidden,
.successMsg.hidden {
  display: none;
}

.txt-orange {
  color: var(--orange);
}

.popup-content {
  border: none !important;
  background: transparent !important;
}

@media only screen and (max-width: 767px) {
  .btn-secondary-action {
    font-size: 1.1rem;
  }

  .popup-content {
    width: 80% !important;
  }
}
