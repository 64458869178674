.sectionDemo {
  background: url("/images/bg-demo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 250px;
  height: auto !important;
  width: 100%;
}

.sectionDemoContent {
  position: absolute;
  top: 55px;
  left: 46%;
  max-width: 400px;
  height: auto !important;
}

.sectionDemoTitle {
  font-size: 1.9rem;
  color: var(--main-color);
}

.sectionDemoButton {
  border: 1px solid var(--main-color);
  border-radius: 10px;
  color: var(--main-color);
  padding: 0 0.5rem;
  line-height: 1.5rem;
  font-size: 1rem;
  width: 200px;
  margin: 16px 0 0;
}

@media only screen and (max-width: 767px) {
  .sectionDemo {
    background-size: 300% auto;
    background-position: -400px center;
  }

  .sectionDemoContent {
    top: 0;
    left: 15%;
    width: 80%;
  }

  .sectionDemoTitle {
    width: 80%;
  }
}
