.topBar {
  background: var(--main-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 48px;
  z-index: 999;
}

.topBarLogo {
  width: auto;
  height: 32px;
}

.topBarHamburger {
  display: none;
}

.topBarSectionActions {
  margin: 0;
  padding: 0;
  list-style: none;
}

.topBarSectionActionsLeft {
  align-self: flex-start;
}

.topBarSectionActions li {
  font-size: 18px;
  margin: 0 8px;
  cursor: pointer;
  display: inline-block;
}

.topBarSectionActions li:hover {
  font-weight: bold;
}

.topBarSectionActions li a {
  margin: 0 !important;
}

.topBarSectionActionsLogo {
  display: none !important;
}

.topBarSocialActions {
  list-style: none;
  margin: 0;
  padding: 0;
}

.topBarSocialActions li {
  margin: 0 4px;
  padding: 0;
  text-align: center;
  display: inline-block;
}

.topBarSocialActionsLang img {
  border-radius: 3px;
  border: none;
}

.topBarSocialActions img {
  width: auto;
  height: 16px;
}

@media only screen and (max-width: 768px) {
  .topBar {
    justify-content: flex-start;
    z-index: 2;
    background: var(--main-color);
    padding-bottom: 8px;
  }

  .topBarHamburger {
    display: block;
    margin-right: 16px;
    width: 24px;
    height: auto;
    z-index: 9999;
  }

  .topBarSectionActions {
    position: fixed;
    left: -100%;
    width: 100%;
    bottom: 0;
    top: 0;
    background-color: var(--main-color);
    color: #fff;
    padding: 70px 16px 0;
    text-align: center;
    transition: left 0.3s ease-in-out;
    z-index: 9998;
  }

  .topBarSectionActionsToggled {
    left: 0;
    transition: left 0.3s ease-in-out;
  }

  .topBarSectionActionsLogo {
    display: inline-block !important;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #eee3;
  }

  .topBarSectionActions li {
    width: 100%;
    line-height: 48px;
    font-size: 24px;
  }

  .topBarSectionActions li:hover {
    font-weight: bold;
  }

  .topBarSocialActions {
    align-content: flex-end;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
