.sectionContactUs {
  background: var(--main-color);
  background: url("/images/bg-section-contact-us-main.png");
  background-repeat: no-repeat;
  background-position: bottom left;
}

.sectionContactUsContent {
  background: url("/images/bg-contact-secondary.png");
  background-repeat: no-repeat;
  background-size: 200px auto;
  background-position: 150px bottom;
  height: 100vh;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}

.sectionContactUsColumn {
  flex: 1;
  padding: 32px;
}

.sectionContactUs h2 {
  font-size: 40px;
  margin: 8px 0;
}

.sectionContactUsColumn p,
.sectionContactUsColumn li {
  color: #f8f1f1;
  font-size: 14px;
  width: 100%;
}

.sectionContactUsColumn ul {
  display: block;
  list-style-type: disc;
  margin: 16px 0 0 64px;
}

.sectionContactUsColumn p:first-of-type {
  color: var(--main-font-color);
  font-size: 18px;
  margin: 0 0 16px;
}

.sectionContactUsForm {
  background: rgba(255, 255, 255, 0.16);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sectionContactUsFormField {
  width: 48%;
  margin: 8px 0;
}

.sectionContactUsFormFieldBtn {
  text-align: right;
}

.sectionContactUsFormField label,
.sectionContactUsFormField input {
  width: 100%;
}
.sectionContactUsFormField input {
  border: 1px solid transparent;
  border-radius: 8px;
  line-height: 24px;
}

.sectionContactUsFormFieldFull {
  width: 100%;
}

.sectionContactUsSendBtn {
  padding: 0 32px;
  color: var(--main-font-color);
  line-height: 32px;
  font-size: 18px;
  border-radius: 16px;
  max-width: 350px;
  margin: 32px auto;
  background: #0098ec;
}

@media only screen and (max-width: 767px) {
  .sectionContactUsContent {
    flex-direction: column !important;
    justify-content: flex-start;
    padding-top: 70px;
    text-align: center;
    background: none;
  }

  .sectionContactUsColumn {
    padding: 8px;
    margin-bottom: 16px;
  }

  .sectionContactUs h2 {
    font-size: 24px;
    margin: 16px 0;
  }

  .sectionContactUsColumn ul {
    display: block;
    list-style-type: disc;
    margin: 16px 0 0;
  }

  .sectionContactUsColumn p:first-of-type {
    color: var(--main-font-color);
    font-size: 18px;
    margin: 0 0 16px;
  }

  .sectionContactUsForm {
    background: rgba(255, 255, 255, 0.16);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .sectionContactUsFormField {
    width: 48%;
    margin: 8px 0;
  }
  .sectionContactUsFormField label,
  .sectionContactUsFormField input {
    width: 100%;
  }
  .sectionContactUsFormField input {
    border: 1px solid transparent;
    border-radius: 8px;
    line-height: 24px;
  }

  .sectionContactUsFormField,
  .sectionContactUsFormFieldFull,
  .sectionContactUsFormFieldBtn {
    width: 100%;
  }

  .sectionContactUsSendBtn {
    width: 100%;
  }
}
