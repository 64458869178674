.terms {
  max-width: 80%;
  margin: 64px auto;
}

.terms h1 {
  font-size: 1.5rem;
  text-align: center;
  margin: 16px auto;
}

.terms h2 {
  font-size: 1.4rem;
  margin: 8px auto;
}

.terms h3 {
  font-size: 1.3rem;
  margin: 8px auto;
}

.terms p {
  font-size: 0.8rem;
  margin: 4px auto;
  font-weight: normal;
}

.terms ul {
  margin: 0 32px;
  font-size: 0.8rem;
}
