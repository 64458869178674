.sectionFeatures {
  background: url("/images/bg-features.png");
  background-repeat: no-repeat;
  background-size: 40vh auto;
  background-position: bottom right;
}

.sectionFeaturesContent {
  justify-content: center;
  max-width: 1024px;
  margin: 0 auto;
}

.sectionFeaturesContent h2 {
  font-size: 2.5rem;
  color: #fff;
}

.sectionFeaturesList {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 32px 16px;
  list-style: none;
}

.sectionFeaturesList > li {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 25%;
  text-align: center;
  padding: 32px 8px;
  height: 250px;
  background: #2319b5;
  border-radius: 25px;
  margin: 8px;
}

.sectionFeaturesList h3 {
  font-size: 1.4rem;
  color: var(--main-font-color);
  line-height: 22px;
  max-width: 300px;
  margin: 8px auto;
}

.sectionFeaturesList img {
  height: 32px;
  width: auto;
}

.sectionFeaturesList p {
  color: var(--main-font-color);
  font-size: 1rem;
  max-width: 300px;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .sectionFeaturesContent {
    padding-top: 70px;
    justify-content: flex-start;
    height: auto;
  }

  .sectionFeaturesContent h2 {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 0 16px;
  }

  .sectionFeaturesList {
    padding: 16px;
  }

  .sectionFeaturesList li {
    flex-basis: 60%;
    text-align: center;
    padding: 16px 8px;
    margin: 16px 32px;
    height: auto;
  }

  .sectionFeaturesList li:nth-child(even) {
    background: transparent;
  }

  .sectionFeaturesList h3 {
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    margin: 8px auto;
  }

  .sectionFeaturesList img {
    height: 24px;
    width: auto;
  }

  .sectionFeaturesList p {
    font-size: 14px;
    width: 100%;
  }
}
